/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Image, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1ijhuvf --style3 --full" anim={""} name={"einleitung"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Subtitle className="subtitle-box" content={"Tanya Dvorak"}>
              </Subtitle>

              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Hand-crafted pottery"}>
              </Title>

              <Button className="btn-box btn-box--shape4 mt--20" content={"Fotogalerie"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"dienstleistungen"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/162/img-1_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/162/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/162/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/162/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/162/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/162/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"Made to order"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/162/img-2_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/162/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/162/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/162/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/162/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/162/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"Pottery repair"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/162/img-3_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/162/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/162/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/162/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/162/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/162/img-3_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"Konsultation"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"zitat"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1670}}>
              
              <Text className="text-box fs--30 w--400 lh--16" content={"\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.\""}>
              </Text>

              <Text className="text-box" content={"— Tanya Dvorak"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"information-1"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/162/img-1_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/162/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/162/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/162/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/162/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/162/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Konsultation</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.&nbsp;Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

              <Button className="btn-box btn-box--shape4 mt--20" content={"Kontaktieren"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"information-2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Made to order"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.&nbsp;Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--shape4 mt--20" content={"Fotogalerie"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/162/img-2_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/162/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/162/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/162/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/162/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/162/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"footer"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Tanya Dvorak"}>
              </Title>

              <Text className="text-box" content={"+49 797 811 2X05<br>info@vase-stranky.com<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}